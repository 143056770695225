


























































import { bus, i18n } from '@/main';
import { Component, Vue, Watch } from 'vue-property-decorator'
import CatalogSongResults from './CatalogSongResults.vue'
import FButton from '@/components/Button/FButton.vue'
import LargeIcon from '@/components/Icon/LargeIcon.vue'
import { BusyService } from '@/services/BusyService';
import { CatalogSongsService } from '@/services/CatalogSongsService';
import { asyncDownload } from '@/functions/DownloadFunctions';
import { DynamicTranslationService } from '@/services/DynamicTranslationService';

@Component({
  metaInfo: () => ({
    title: DynamicTranslationService.catalogDetailsTitle
  }), 
  components:{ 
    CatalogSongResults, 
    FButton, 
    LargeIcon
  }
})
export default class CatalogDetail extends Vue {

  store = CatalogSongsService;
  loading = false;

  get search(): string{ 
    return this.$route.query.s as string || '';
  }

  async mounted() { 
    BusyService.showBusy();
    this.loading = true;
    this.store.catalogId = this.$route.params.id;
    try{
      this.store.getQueryParams();
      await this.store.performSearch();
      this.loading = false;
      BusyService.hideBusy();
    }catch(error){ 
      this.loading = false;
      BusyService.hideBusy();
      throw error;
    }
    
    bus.$on('territoryUpdated', this.performSearch);
  }
  
  beforeDestroy() {
    this.store.reset();
    bus.$off('territoryUpdated');
  }

  @Watch('$route')
  async performSearch(){ 
    BusyService.showBusy();
    await this.store.performSearch();
    BusyService.hideBusy();
  }

  downloadAll() { 
    asyncDownload(this.store.downloadAllUrl, `${this.store.catalogId}.csv`);
  }
}
